import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { requestPassword, sendForgotPasswordCode } from '../core/_requests';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import notify from '../../../helpers/notify';
import getTheme from '../../../helpers/getTheme';

const initialValues = {
  code: '',
  password: '',
};

const forgotPasswordSchema = Yup.object().shape({
  code: Yup.string()
    .min(5, 'Minimum 5 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

interface IProps {
  email: string;
}

export function ForgotPasswordChangePass({ email }: IProps) {
  const themeMode = useThemeMode();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<string | undefined>('');

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors('');
      requestPassword(email, values.code, values.password)
        .then((r) => {
          setTimeout(() => {
            setLoading(false);
            navigate('/sign-in');
          }, 3000);
          notify(
            'Password has been successfully changed.',
            'success',
            getTheme(themeMode.mode)
          );
        })
        .catch((err) => {
          const res = err.response;
          console.log('status: ', res.status, res.data.message);
          setHasErrors(res.data.message);
          setLoading(false);
          setSubmitting(false);
          setStatus('The login detail is incorrect');
        });
    },
  });

  const onResend = async () => {
    await sendForgotPasswordCode(email);
  };

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework text-center"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <p className="text-center fs-5 mb-5">
          Please verify your email.
          <br />
          We have sent you a one time passcode
        </p>
        <input
          type="text"
          placeholder="Enter Code"
          autoComplete="off"
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid mb-5',
            {
              'is-invalid': formik.touched.code && formik.errors.code,
            },
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
        />
        <div className="position-relative mb-5">
          <input
            type="password"
            placeholder="Password"
            autoComplete="off"
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid mb-5',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        {hasErrors && (
          <div className="fv-plugins-message-container mb-10">
            <div className="fv-help-block">
              <span role="alert">{hasErrors}</span>
            </div>
          </div>
        )}

        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-lg btn-primary fw-bolder mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Change Password</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <p className="text-center fs-5 mb-5">
          Didn't receive the email? Try checking
          <br />
          your junk or spam folders.
        </p>
        <p
          className="text-sm-start text-dark cursor-pointer"
          onClick={onResend}
        >
          Resend
        </p>
      </form>
      <ToastContainer />
    </>
  );
}
