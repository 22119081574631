import { lazy, FC, Suspense, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { DisableSidebar } from '../../_metronic/layout/core';
import { WithChildren } from '../../_metronic/helpers';
import { useAuth } from '../modules/auth';

interface IProps {
  isAdminProp: boolean;
}

const PrivateRoutes = ({ isAdminProp: isAdmin }: IProps) => {
  const authData = useAuth();
  const AccountPage = lazy(() => import('../pages/user/AccountPage'));
  const CreateCompany = lazy(() => import('../pages/user/CreateCompany'));
  const CurrentCompany = lazy(() => import('../pages/user/CurrentCompany'));
  const ChangePasswordPage = lazy(
    () => import('../pages/user/ChangePasswordPage')
  );
  const PlansPage = lazy(() => import('../pages/admin/plans/PlansPage'));
  const PaymentMethods = lazy(() => import('../pages/user/PaymentMethods'));
  const ChangePlanPage = lazy(
    () => import('../pages/admin/plans/ChangePlanPage')
  );
  const UsersPage = lazy(() => import('../pages/admin/users/UsersPage'));
  const UpdateUserPage = lazy(
    () => import('../pages/admin/users/UpdateUserPage')
  );
  const CreateUserPage = lazy(
    () => import('../pages/admin/users/CreateUserPage')
  );
  const BillingHistory = lazy(() => import('../pages/user/BillingHistory'));
  const BillingHistoryAdmin = lazy(() => import("../pages/admin/BillingHistoryAdmin"));
  const ActivityLog = lazy(() => import("../pages/admin/ActivityLog"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="/*"
          element={<Navigate to={isAdmin ? '/dashboard' : '/account'} />}
        />
        {/* Pages */}
        {isAdmin && (
          <>
            <Route
              path="/users"
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
            <Route
              path="/plans"
              element={
                <SuspensedView>
                  <PlansPage />
                </SuspensedView>
              }
            />
            <Route
              path="/plans/:id"
              element={
                <SuspensedView>
                  <ChangePlanPage />
                </SuspensedView>
              }
            />
            <Route
              path="/users/:id"
              element={
                <SuspensedView>
                  <UpdateUserPage />
                </SuspensedView>
              }
            />
            <Route
              path="/users/create"
              element={
                <SuspensedView>
                  <CreateUserPage />
                </SuspensedView>
              }
            />
            <Route
              path="/billing"
              element={
                <SuspensedView>
                  <BillingHistoryAdmin />
                </SuspensedView>
              }
            />
          </>
        )}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route
          path="/account"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="/companies/create"
          element={
            <SuspensedView>
              <CreateCompany />
            </SuspensedView>
          }
        />
        <Route
          path="/companies/:id"
          element={
            <SuspensedView>
              <CurrentCompany />
            </SuspensedView>
          }
        />
        <Route
          path="/change-password"
          element={
            <SuspensedView>
              <ChangePasswordPage />
            </SuspensedView>
          }
        />
        <Route
          path="/payment-methods"
          element={
            <SuspensedView>
              <PaymentMethods />
            </SuspensedView>
          }
        />
        <Route
          path="/billing-history"
          element={
            <SuspensedView>
              <BillingHistory />
            </SuspensedView>
          }
        />
        <Route
          path="/log"
          element={
            <SuspensedView>
              {/*<ChangePasswordPage />*/}
              <ActivityLog />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  );
};

export { PrivateRoutes };
