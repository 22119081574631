/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
const AuthLayout = () => {
  const themeLocal = localStorage.getItem('kt_theme_mode_value');
  useEffect(() => {
    if (themeLocal === 'dark') {
      document.body.classList.remove('bg-white');
      document.body.classList.add('bg-light');
      return () => {
        document.body.classList.remove('bg-light');
        // document.body.classList.add('bg-light')
      };
    }
    if (themeLocal === 'light') {
      document.body.classList.remove('bg-light');
      document.body.classList.add('bg-white');
      return () => {
        document.body.classList.remove('bg-white');
        // document.body.classList.add('bg-white')
      };
    }
  }, []);

  return themeLocal === 'dark' ? (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          '/media/illustrations/sketchy-1/14.png'
        )})`,
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <div className="w-lg-500px rounded bg-light shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          '/media/illustrations/sketchy-1/14.png'
        )})`,
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <div className="w-lg-500px rounded bg-white shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="sign-in" element={<Login />} />
      <Route path="sign-up" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<Login />} />
      <Route path="*" element={<Navigate to="sign-in" />} />
    </Route>
  </Routes>
);

export { AuthPage };
