import axios from 'axios';

const MAIN_URL = process.env.REACT_APP_API_URL;

export const CHANGE_PASSWORD = `${MAIN_URL}/v1/me`;
export const GET_ADMINS_MENU = `${MAIN_URL}/v1/constants`;
export const GET_PLANS = `${MAIN_URL}/v1/plans?sorting[field]=order&sorting[sort]=asc`;
export const GET_PAYMENT_METHOD = `${MAIN_URL}/v1/payment_methods`;
export const COMPANY_URL = `${MAIN_URL}/v1/companies`;
export const GET_SECRET_KEY = `${MAIN_URL}/v1/payment_methods/intent`;
export const SUBSCRIPTION = `${MAIN_URL}/v1/subscriptions`;
export const GET_INVOICES = `${MAIN_URL}/v1/invoices/upcoming`;
export const GET_All_INVOICES = `${MAIN_URL}/v1/invoices`;

export async function getCompanies(token: string) {
  const res = await axios.get(COMPANY_URL, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function getCompanyByID(token: string, id: string) {
  const res = await axios.get(`${COMPANY_URL}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function reactivateCompany(token: string, id: string) {
  const res = await axios.get(`${SUBSCRIPTION}/${id}/reactivate`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function cancelSubscription(token: string, id: string) {
  const res = await axios.delete(`${SUBSCRIPTION}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function getInvoices(
  token: string,
  subscription_id: string,
  new_plan_id: string
) {
  const res = await axios.post(
    GET_INVOICES,
    { subscription_id, new_plan_id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res;
}

export async function changePlan(
  token: string,
  id: string,
  new_plan_id: string
) {
  const res = await axios.put(
    `${SUBSCRIPTION}/${id}`,
    { id, new_plan_id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res;
}

export async function updateCompany(
  token: string,
  id: string,
  name: string,
  carrier_id: string
) {
  const res = await axios.put(
    `${COMPANY_URL}/${id}`,
    { id, name, carrier_id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res;
}

export async function changePassword(
  password: string,
  new_password: string,
  token: string
) {
  const res = axios.put(
    CHANGE_PASSWORD,
    { password, new_password },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res;
}

export async function getAdminsMenuItems(token: string) {
  const { data } = await axios.get(GET_ADMINS_MENU, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
}

export async function getSubscriptionPlans(token: string) {
  const res = await axios.get(GET_PLANS, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function getPaymentMethods(token: string, userId?: string | null) {
  if (userId) {
    const res = await axios.get(`${GET_PAYMENT_METHOD}?user_id=${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  }
  const res = await axios.get(GET_PAYMENT_METHOD, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function createCompany(
  token: string,
  name: string,
  carrier_id: string,
  id?: string
) {
  if (id) {
    const res = await axios.post(
      `${COMPANY_URL}?owner_id=${id}`,
      { name, carrier_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  }
  const res = await axios.post(
    COMPANY_URL,
    { name, carrier_id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res;
}

export async function createSubscription(
  token: string,
  companyId: string,
  planId: string,
  payId: string,
  id?: string | null
) {
  if (id) {
    const res = await axios.post(
      `${SUBSCRIPTION}?user_id=${id}`,
      { company_id: companyId, plan_id: planId, payment_method_id: payId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  }
  const res = await axios.post(
    SUBSCRIPTION,
    { company_id: companyId, plan_id: planId, payment_method_id: payId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res;
}

export async function deletePaymentMethod(token: string, id: string) {
  const res = await axios.delete(`${GET_PAYMENT_METHOD}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function getSecretKey(token: string, userId?: string | null) {
  if (userId) {
    const res = await axios.get(`${GET_SECRET_KEY}?user_id=${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  }
  const res = await axios.get(GET_SECRET_KEY, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function changePaymentMethod(
  token: string,
  id: string,
  payment_method_id: string,
  userId?: string | null
) {
  if (userId) {
    const res = await axios.put(
      `${SUBSCRIPTION}/${id}`,
      {
        id,
        user_id: userId,
        payment_method_id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  }
  const res = await axios.put(
    `${SUBSCRIPTION}/${id}`,
    {
      id,
      payment_method_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res;
}

export async function getAllInvoices(token: string, query: string, page: number) {
  const res = await axios.get(`${GET_All_INVOICES}?terms=${query}&page=${page}&per_page=5`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  res.data.lastQuery = query
  return res;
}
