import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function useGetUrlParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [companyNameParam, setCompanyNameParam] = useState<string | null>(null);
  const [carrierIdParam, setCarrierIdParam] = useState<string | null>(null);

  useEffect(() => {
    const companyName = searchParams.get('companyName');
    const companyId = searchParams.get('carrierId');
    if (companyName && companyId) {
      setCompanyNameParam(companyName);
      setCarrierIdParam(companyId);
      localStorage.setItem(
        'urlParams',
        JSON.stringify({ companyName, companyId })
      );
    }
  }, []);

  return companyNameParam && carrierIdParam
    ? {
        companyName: companyNameParam,
        carrierId: carrierIdParam,
      }
    : undefined;
}

export default useGetUrlParams;
