import { toast } from 'react-toastify';

const notify = (message: string, type: string, themeMode: 'light' | 'dark') => {
  if (type === 'success') {
    return toast.success(message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: themeMode,
    });
  }
  if (type === 'error') {
    return toast.error(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: themeMode,
    });
  }
};

export default notify;
