/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../helpers';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { useEffect, useState } from 'react';
import { getAdminsMenuItems } from '../../../../app/pages/services/_accounts-requests';
import { useAuth } from '../../../../app/modules/auth';

export function AsideMenuMain() {
  const authData = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminMenu, setAdminMenu] = useState<
    { alias: string; title: string }[]
  >([]);
  const intl = useIntl();

  useEffect(() => {
    if (authData.currentUser!.isAdmin.includes('admin')) {
      getAdminsMenuItems(authData.auth!.api_token)
        .then((r) => {
          setAdminMenu(r.menu);
          setIsAdmin(true);
        })
        .catch((r) => {
          setAdminMenu([]);
          setIsAdmin(false);
        });
    }
  }, []);

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Menu
          </span>
        </div>
      </div>
      {!isAdmin ? (
        <>
          <AsideMenuItem
            to="/dashboard"
            icon="/media/icons/duotune/arrows/arr001.svg"
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/account"
            icon="/media/icons/duotune/arrows/arr001.svg"
            title="Account"
            fontIcon="bi-layers"
          />
        </>
      ) : (
        <>
          {adminMenu.map((el) => {
            return (
              <AsideMenuItem
                key={el.alias + el.title}
                to={el.alias}
                icon="/media/icons/duotune/arrows/arr001.svg"
                title={el.title}
                fontIcon="bi-layers"
              />
            );
          })}
        </>
      )}

      {/*<AsideMenuItem*/}
      {/*  to='/builder'*/}
      {/*  icon='/media/icons/duotune/arrows/arr001.svg'*/}
      {/*  title='Layout Builder'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*/>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/account'*/}
      {/*  title='Account'*/}
      {/*  fontIcon='bi-archive'*/}
      {/*  icon='/media/icons/duotune/arrows/arr001.svg'*/}
      {/*>*/}
      {/*<AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>*/}
      {/*<AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />*/}
      {/*<AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />*/}
      {/*<AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />*/}
      {/*<AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />*/}
      {/*<AsideMenuItem*/}
      {/*  to='/crafted/pages/profile/connections'*/}
      {/*  title='Connections'*/}
      {/*  hasBullet={true}*/}
      {/*/>*/}
      {/*</AsideMenuItemWithSub>*/}

      {/*<AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
      {/*  <AsideMenuItem*/}
      {/*    to='/crafted/pages/wizards/horizontal'*/}
      {/*    title='Horizontal'*/}
      {/*    hasBullet={true}*/}
      {/*  />*/}
      {/*  <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/crafted/accounts'*/}
      {/*  title='Accounts'*/}
      {/*  icon='/media/icons/duotune/arrows/arr001.svg'*/}
      {/*  fontIcon='bi-person'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/error'*/}
      {/*  title='Errors'*/}
      {/*  fontIcon='bi-sticky'*/}
      {/*  icon='/media/icons/duotune/arrows/arr001.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/crafted/widgets'*/}
      {/*  title='Widgets'*/}
      {/*  icon='/media/icons/duotune/arrows/arr001.svg'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/apps/chat'*/}
      {/*  title='Chat'*/}
      {/*  fontIcon='bi-chat-left'*/}
      {/*  icon='/media/icons/duotune/arrows/arr001.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItem*/}
      {/*  to='/apps/user-management/users'*/}
      {/*  icon='/media/icons/duotune/arrows/arr001.svg'*/}
      {/*  title='User management'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*/>*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content'>*/}
      {/*    <div className='separator mx-1 my-4'></div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </>
  );
}
